
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TemplateFormDetails from '@/components/modals/shared/template/TemplateFormDetails.vue'
import type { TemplateType } from '@/components/modals/shared/SelectTemplateModal.vue'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TemplateFormDetails,
    TmButton,
  },
  props: {
    descriptionHint: {
      type: String,
    },
    template: {
      type: Object as PropType<TemplateType>,
    },
  },
  emits: ['change'],
})
