
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useTasks from '@/compositions/tasks/useTasks'
import TemplateForm from '@/components/modals/shared/template/TemplateForm.vue'
import { tasksTemplates } from '@/definitions/tasks/data'

export default defineComponent({
  components: {
    TemplateForm,
    TmModal,
    TmButton,
  },
  setup() {
    const { openTemplateModal } = useTasks()

    const modal = ref()
    const handleChange = () => {
      modal.value.hideModal()
      openTemplateModal()
    }

    return {
      modal,
      tasksTemplates,
      handleChange,
    }
  },
})
